export const discount: Record<string, string> = {
  'wm-three-months-129': '25%',
  'wm-six-months-129': '50%'
};

export const getPrice = (playType: string, price: string) => {
  switch (playType) {
    case 'wm-three-months-129':
    case 'wm-six-months-129':
      const discountAmount = (parseFloat(price) * parseFloat(discount[playType])) / 100;

      return (parseFloat(price) - discountAmount).toFixed(2);
    default:
      return price;
  }
};

export const freePlanCode = 'limited-access';

export const getHint = (ppID?: string) => {
  switch (ppID) {
    case 'bundle-semaglutide-one-month':
    case 'bundle-semaglutide-three-month':
    case 'bundle-semaglutide-six-month':
    case 'bundle-tirzepatide-one-month':
    case 'bundle-tirzepatide-three-month':
    case 'bundle-tirzepatide-six-month':
      return '*Restricted dosing; may be additional charge for high doses of medication.';
    case 'wm-tt-one-month':
      return '*At-home testing may not be available in all regions and will incur an additional charge if elected.';
    default:
      break;
  }
};
