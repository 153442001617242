import { FormEvent, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Common } from '@thecvlb/design-system';
import { useFlag } from '@unleash/proxy-client-react';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { AnimatePresence } from 'framer-motion';

import { selectLatestHealthMetrics, selectOrchestrate } from 'store';
import { setSignupOptions } from 'store/signup/signupSlice';

import BMILine from 'pages/SignUp/WM/BMILine';

import { MixedSignupStepProps } from 'containers/SignUp/Content/content.types';
import FadeWrapper from 'shared/animationWrappers/FadeWrapper';
import CheckboxGroup from 'shared/CheckboxGroup';
import ExpandablePanel from 'shared/ExpandablePanel';
import { notifyError } from 'shared/Toast/Toast';

import { useAppDispatch, useAppSelector, useQuery } from 'hooks';
import useSubmitOrchestrateForm from 'hooks/useSubmitOrchestrateForm';
import { FeatureFlag, FlowTypes, PathName, PlanTypes, SignUpSteps } from 'utils/enums';
import { buildSearch, calculateBMI } from 'utils/helpers';

import Advantage from '../parts/Advantage';
import BackToMainWebsiteButton from '../parts/BackToMainWebsiteButton';
import Heading from '../parts/Heading';
import LearnMoreAboutBMILink from '../parts/LearnMoreAboutBMILink';
import PossibleWeightLoss from '../parts/PossibleWeightLoss';
import Processing from '../parts/Processing';
import { getNewTargetWeight } from '../wm.settings';

const ITEMS = [
  {
    label: 'Heart disease',
    value: 'heart_disease'
  },
  {
    label: 'High blood pressure',
    value: 'high_blood_pressure'
  },
  {
    label: 'High cholesterol',
    value: 'high_cholesterol'
  },
  {
    label: 'Type II diabetes',
    value: 'type_ii_diabetes'
  },
  {
    label: 'Sleep apnea',
    value: 'sleep_apnea'
  },
  {
    label: 'High blood sugar',
    value: 'high_blood_sugar'
  },
  {
    label: 'Osteoarthritis of the hips or knees',
    value: 'osteoarthritis_of_the_hips_or_knees'
  },
  {
    label: 'Fatty liver disease',
    value: 'fatty_liver_disease'
  },
  {
    label: 'No (lab work required)',
    value: 'none'
  }
];

const HeightAndWeight: React.FC<
  MixedSignupStepProps & { isMigrationToTT?: boolean; onToggleLabsStep?: (v: boolean) => void }
> = ({ moveToStep, selectedFlow, isMigrationToTT = false, onToggleLabsStep }) => {
  const dispatch = useAppDispatch();
  const isShowExtendedUI = useFlag(FeatureFlag.ExtendedHeightAndWeightUI);

  const [activeElement, setActiveElement] = useState<string>('');
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const query = useQuery();
  const navigate = useNavigate();
  const [data, setData] = useState({
    heightFt: '',
    heightIn: '',
    weight: ''
  });
  const {
    mif_qa: mifQA,
    user: { email }
  } = useAppSelector(selectOrchestrate);
  const { height, weight } = useAppSelector(selectLatestHealthMetrics);
  const prefix = selectedFlow === 'authorized' ? 's=height-and-weight' : '';

  const timer = useRef<ReturnType<typeof setTimeout>>();
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const { send, isLoading } = useSubmitOrchestrateForm();

  const errors = {
    heightFt: +data.heightFt > 9 ? { message: 'Max height is 9ft', type: 'max' } : undefined,
    heightIn: +data.heightIn > 11 ? { message: 'Incorrect value', type: 'max' } : undefined,
    weight:
      data.weight && +data.weight < 99
        ? { message: 'Min weight is 100lbs', type: 'min' }
        : +data.weight > 1500
          ? { message: 'Max weight is 1000lbs', type: 'max' }
          : undefined
  };

  const isValid = Object.values(errors).every((v) => !v);

  const handleSelect = (v: number | string) => {
    const stringValue = String(v);
    if (v === 'none') {
      setSelectedItems(selectedItems.includes('none') ? [] : ['none']);
      return;
    }
    const newArray = selectedItems.includes(stringValue)
      ? selectedItems.filter((i) => i !== stringValue)
      : [...selectedItems, stringValue];
    setSelectedItems(newArray.filter((i) => i !== 'none'));
  };

  const BMI = Number(calculateBMI(data));

  const handleSubmitForm = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onToggleLabsStep?.(BMI >= 27 && BMI < 30);
    if (!isValid) {
      return;
    }
    const height = Number(data.heightFt) * 12 + +data.heightIn;
    navigate({ search: buildSearch(prefix, 'hideProgress=true&subStep=processing') });
    timer.current = setTimeout(() => {
      const currentDate = dayjs().format('YYYY-MM-DD');
      send(
        'combined',
        [
          {
            key: 'mif_qa',
            value: [
              { answer: height, question: 'height' },
              { answer: Number(data.weight), question: 'weight' },
              {
                answer: BMI,
                question: 'bmi_value'
              },
              {
                answer: Number(getNewTargetWeight(Number(data.weight))),
                question: 'weight_goal_value'
              }
            ]
          },
          {
            key: 'mif',
            value: {
              bmi: {
                date: currentDate,
                value: BMI
              },
              height: {
                date: currentDate,
                value: height
              },
              weight: {
                date: currentDate,
                value: Number(data.weight)
              }
            }
          }
        ],
        () => {
          if (selectedFlow === FlowTypes.TripleTherapy || isMigrationToTT) {
            if (BMI < 25) {
              return navigate(
                {
                  search: buildSearch(prefix, `hideProgress=true&subStep=low-bmi&answer=none`)
                },
                {
                  replace: true
                }
              );
            } else {
              navigate(
                { search: buildSearch(prefix, `subStep=weight-loss&hideProgress=true`) },
                { replace: true }
              );
              setTimeout(() => {
                moveToStep('next');
              }, 2700);
            }
          } else {
            if (BMI >= 25 && BMI < 27) {
              navigate(
                {
                  search: buildSearch(
                    prefix,
                    `subStep=tt-therapy&answer=none&shouldEmulateBack=true`
                  )
                },
                { replace: true }
              );
            } else {
              if (isShowExtendedUI || BMI < 25) {
                navigate(
                  {
                    search: buildSearch(
                      prefix,
                      `subStep=results&answer=none&shouldEmulateBack=true`
                    )
                  },
                  { replace: true }
                );
              } else {
                moveToStep('next', { searchParams: '?shouldEmulateBack=true' });
              }
            }
          }
        }
      );
    }, 2700);
  };

  const handleContinue = () => {
    if (BMI < 27) {
      return navigate({
        search: buildSearch(prefix, `hideProgress=true&subStep=low-bmi&answer=none`)
      });
    }
    if (BMI >= 27 && BMI < 30) {
      return navigate({
        search: buildSearch(prefix, `subStep=extra-question&shouldEmulateBack=true`)
      });
    }
    if (selectedFlow === FlowTypes.BlueLineFlow) {
      navigate(
        { search: buildSearch(prefix, `subStep=weight-loss&hideProgress=true`) },
        { replace: true }
      );
      setTimeout(() => {
        moveToStep('next');
      }, 2700);
    } else {
      moveToStep('next', { searchParams: '?shouldEmulateBack=true' });
    }
  };

  const handleSubmitExtra = () => {
    onToggleLabsStep?.(selectedItems.includes('none'));
    send(
      'mif_qa',
      [
        {
          answer: selectedItems as unknown as string,
          question: 'medical_conditions_based_on_bmi',
          version: 2
        }
      ],
      () => {
        moveToStep('next', { searchParams: '?shouldEmulateBack=true' });
      }
    );
  };

  const subStep = query.get('subStep');

  const handleProspect = () => {
    if (selectedFlow === 'authorized') {
      return navigate(PathName.Dashboard);
    }

    const answer = query.get('answer');
    if (answer && !!email) {
      send(
        'mif_qa',
        [{ answer: 'true', question: 'is_low_bmi' }],
        () => {
          moveToStep(-1);
        },
        'PROSPECT'
      );
    } else {
      moveToStep(-1);
    }
  };

  const moveToTT = () => {
    dispatch(setSignupOptions({ flow: FlowTypes.TripleTherapy }));
    navigate(
      `${PathName.SignUp}/${PlanTypes.WeightManagement}/${FlowTypes.TripleTherapy}/${SignUpSteps.WMBasicInfo}?shouldEmulateBack=true`
    );
  };

  const newTargetWeight = getNewTargetWeight(data.weight);

  useEffect(() => {
    if (subStep === null || subStep === 'processing' || subStep === 'weight-loss') {
      navigate({ search: buildSearch(prefix, 'subStep=form') }, { replace: true });
    }
    const initialData = {
      heightFt: '',
      heightIn: '',
      weight: ''
    };
    try {
      const initialH = mifQA.find((v) => v.question === 'height') ?? { answer: height };
      const initialW = mifQA.find((v) => v.question === 'weight') ?? { answer: weight };
      const conditions = mifQA.find((v) => v.question === 'medical_conditions_based_on_bmi');
      if (initialH) {
        const ft = Math.floor(Number(initialH.answer) / 12);
        const inches = Number(initialH.answer) % 12;
        if (ft) {
          initialData.heightFt = String(ft);
        }
        if (inches) {
          initialData.heightIn = String(inches);
        }
      }
      if (initialW) {
        initialData.weight = String(initialW.answer);
      }
      if (conditions) {
        setSelectedItems(conditions.answer as unknown as string[]);
      }
      setData(initialData);
    } catch (e) {
      notifyError((e as Error).message);
    }
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  return (
    <AnimatePresence mode="wait">
      {subStep === 'results' && (
        <FadeWrapper className="flex h-full flex-col gap-6" key="results">
          <Heading
            category=""
            title={
              <span className="flex gap-1 max-md:flex max-md:flex-col md:items-center md:gap-2">
                <span>Your current BMI is {BMI}</span>
              </span>
            }
          />
          {BMI >= 30 && (
            <Common.Alert type="success" colorableBackground>
              <p>
                {BMI >= 30 ? (
                  <>
                    <span className="font-bold">Good news! </span>
                    <span>
                      Your BMI may qualify you for treatment with GLP-1 medication. A review of your
                      medical history is required.
                    </span>
                  </>
                ) : BMI < 27 ? (
                  'Your BMI does not pre-qualify you for treatment with GLP-1 medication.'
                ) : (
                  'Your BMI may pre-qualify you for treatment with GLP-1 medication. Lab work and a review of your medical history is required.'
                )}
              </p>
            </Common.Alert>
          )}
          <div className="mt-4">
            <BMILine value={BMI} />
          </div>
          {BMI < 30 && (
            <>
              <Common.Alert type="warning" colorableBackground>
                <p>
                  {BMI < 27
                    ? 'Your BMI does not pre-qualify you for treatment with GLP-1 medication.'
                    : 'Your BMI may pre-qualify you for treatment with GLP-1 medication. Lab work and a review of your medical history is required.'}
                </p>
              </Common.Alert>
              <p>
                Clinical guidelines state that patients have a treatable BMI in order to be
                prescribed GLP-1 medication such as Wegovy or Zepbound. This means you must have a
                body mass index (BMI) of 30 or more in order to medically qualify for GLP-1
                treatment. Patients with a BMI between 27-29.9 may qualify but must complete lab
                work before their appointment to confirm a weight related condition (such as type 2
                diabetes, high blood pressure, or high cholesterol). Weight-related lab work is
                covered by the cost of the LifeMD Weight Management Program if completed at a local
                Quest Diagnostics or Labcorp location.
              </p>
            </>
          )}
          <div className="mt-auto flex w-full flex-col gap-4">
            <Common.Button
              className="md:self-center"
              color="blue"
              fullWidthOnMobile
              onClick={handleContinue}
            >
              Continue
            </Common.Button>
            <Common.Button
              className="md:self-center"
              color="white-alt"
              fullWidthOnMobile
              onClick={() => navigate(-1)}
            >
              Resubmit height and weight
            </Common.Button>
          </div>
        </FadeWrapper>
      )}
      {subStep === 'processing' && (
        <FadeWrapper className="flex flex-col place-items-center p-6 shadow-mXl" key="progress">
          <Processing flow={isMigrationToTT ? FlowTypes.TripleTherapy : selectedFlow} />
        </FadeWrapper>
      )}
      {subStep === 'tt-therapy' && (
        <div className="flex h-full flex-col gap-6">
          <Heading
            category="Plan"
            subtitle="Although your low BMI means GLP-1 medications aren't suitable for you, you can still achieve your weight loss goals with our Triple Therapy Program."
            title={
              <span className="flex gap-1 max-md:flex max-md:flex-col md:items-center md:gap-2">
                <span>Your current BMI is {BMI}</span>
              </span>
            }
          />
          <Common.Alert type="warning" colorableBackground>
            Your submitted height and weight does not pre-qualify for treatment with GLP-1
            medication.
          </Common.Alert>
          <div className="rounded-2xl border border-gray-200 px-6 py-4">
            <span className="mb-0.5 block text-mSm md:text-sm">
              <span className="text-gray line-through">$129</span>
              <span className="font-bold text-primary">$99/mo</span>
            </span>
            <span className="font-bold">Triple Therapy</span>
          </div>
          <div className="divide-y divide-gray-200 border-b-2 border-gray-200">
            <ExpandablePanel
              className="px-4 py-6"
              content={
                <p className="flex flex-col text-mSm text-gray-600 md:text-base">
                  <span>
                    The Triple Thereapy program combines the benefits of three medications
                    (Metformin, Burpropion, and Topiramate) to help you achieve and maintain your
                    weight loss goals. Here's how each component works:
                  </span>
                  <span>
                    <span className="mx-2">•</span>Metformin: An anti-diabetic medication primarily
                    used to control blood sugar levels. It also helps reduce appetite, making it
                    easier to manage your diet.
                  </span>
                  <span>
                    <span className="mx-2">•</span> Bupropion: An atypical antidepressant and
                    smoking cessation aid that can boost metabolism and enhance mood, contributing
                    to weight loss efforts.
                  </span>
                  <span>
                    <span className="mx-2">•</span>Topiramate: An anticonvulsant medication that
                    also helps decrease hunger and promotes a feeling of fullness, aiding in weight
                    management.
                  </span>
                </p>
              }
              header={
                <div className="flex cursor-pointer justify-between">
                  <h3 className="text-mSm font-semibold text-gray-700 md:text-sm">How it works</h3>
                  <Common.Icon
                    className={classNames(
                      'ml-1.5 size-5 flex-none text-gray transition-all duration-300 md:h-4 md:w-4',
                      {
                        'rotate-90': '1' === activeElement
                      }
                    )}
                    name="arrow-right"
                  />
                </div>
              }
              isShowContent={'1' === activeElement}
              key="1"
              toggleContent={() => setActiveElement((p) => (p === '1' ? '' : '1'))}
            />
          </div>
          <div className="flex gap-4 max-md:mt-auto max-md:flex-col md:justify-center">
            <Common.Button color="blue" fullWidthOnMobile onClick={moveToTT}>
              Continue with Triple Therapy
            </Common.Button>
            <Common.Button
              color="white-alt"
              fullWidthOnMobile
              onClick={() =>
                navigate({
                  search: buildSearch(prefix, `hideProgress=true&subStep=low-bmi&answer=none`)
                })
              }
            >
              Cancel
            </Common.Button>
          </div>
        </div>
      )}
      {subStep === 'low-bmi' && (
        <FadeWrapper className="flex h-full flex-col gap-6" key="error">
          <h2 className="wm-signup-title text-center" data-testid="header">
            {selectedFlow === FlowTypes.TripleTherapy
              ? 'Sorry, your BMI is too low. You are not a candidate for Triple Therapy weight loss medication.'
              : 'Your BMI does not meet the criteria for GLP-1 weight loss medication.'}
          </h2>
          <Common.Alert type="warning" colorableBackground>
            {selectedFlow === FlowTypes.TripleTherapy ? (
              <p>
                In order to pre-qualify for Triple Therapy treatment, patients must have a BMI above
                25.
              </p>
            ) : (
              <>
                <p className="mb-3 font-bold">
                  In order to pre-qualify for treatment with GLP-1 medications, you must have a BMI
                  of 30 or above — which is classified as obesity.
                </p>
                <p>
                  GLP-1 medication may also be considered for those with a BMI of 27-29.99 if they
                  have at least one weight-related health condition (such as type 2 diabetes, high
                  blood pressure, or high cholesterol). Patients with a BMI between 27 and 29.99
                  will be required to complete labs prior to their appointment.
                </p>
              </>
            )}
          </Common.Alert>
          <div className="flex flex-col gap-2">
            <b className="text-center">Current BMI: {calculateBMI(data)}</b>
            <LearnMoreAboutBMILink className="text-center" />
          </div>
          <BackToMainWebsiteButton loading={isLoading} onClick={handleProspect} />
        </FadeWrapper>
      )}
      {subStep === 'form' && (
        <FadeWrapper className="flex h-full flex-col gap-2" key="form">
          <Heading
            category="Eligibility"
            subtitle="Please tell us your height and current weight"
            title="For us to give you the most accurate results we can"
          />
          <form className="flex h-full flex-col gap-6" onSubmit={handleSubmitForm}>
            <div className="grid grid-cols-2 gap-6">
              <Common.Input
                dataTestId="height_ft_input"
                errors={errors.heightFt}
                helper={errors.heightFt?.message}
                label="Height"
                max={9}
                name="heightFt"
                postText="ft"
                size="lg"
                type="number"
                value={data.heightFt || ''}
                required
                onChange={handleChange}
              />
              <Common.Input
                className="pt-8"
                dataTestId="height_in_input"
                errors={errors.heightIn}
                helper={errors.heightIn?.message}
                max={12}
                min={0}
                name="heightIn"
                postText="in"
                size="lg"
                type="number"
                value={data.heightIn || ''}
                required
                onChange={handleChange}
              />
              <Common.Input
                className="col-span-2"
                dataTestId="weight_lbs_input"
                errors={errors.weight}
                helper={errors.weight?.message}
                label="Weight"
                max={1500}
                name="weight"
                postText="lbs"
                size="lg"
                type="number"
                value={data.weight || ''}
                required
                onChange={handleChange}
              />
            </div>
            <Common.Button
              className="max-md:mt-auto md:mx-auto"
              color="blue"
              disabled={!data.heightFt || !data.weight || isLoading || !isValid}
              isLoading={isLoading}
              type="submit"
              fullWidthOnMobile
            >
              Continue
            </Common.Button>
          </form>
        </FadeWrapper>
      )}

      {subStep === 'extra-question' && (
        <FadeWrapper className="flex flex-col place-items-center gap-6" key="extra-question">
          <h2 className="wm-signup-title" data-testid="header">
            Do you have any of the following medical conditions?
          </h2>
          <Common.Alert type="warning" colorableBackground>
            <p className="mb-3">
              Patients who have a BMI of 27-29.99 are required to complete labs before their
              appointment unless they have one of the following health conditions.
            </p>
            <b>Current BMI: {calculateBMI(data)}</b>
          </Common.Alert>
          <div className="w-full">
            <CheckboxGroup items={ITEMS} selectedItems={selectedItems} onSelect={handleSelect} />
          </div>
          <Common.Button
            className="max-md:mt-auto md:mx-auto"
            color="blue"
            disabled={
              !data.heightFt || !data.weight || isLoading || !isValid || !selectedItems.length
            }
            isLoading={isLoading}
            fullWidthOnMobile
            onClick={handleSubmitExtra}
          >
            Continue
          </Common.Button>
        </FadeWrapper>
      )}
      {subStep === 'weight-loss' && (
        <FadeWrapper className="flex h-full flex-col place-items-center p-6" key="alt">
          {selectedFlow === FlowTypes.BlueLineFlow ? (
            <PossibleWeightLoss
              possibleWL={Math.round(Number(data.weight) - Number(newTargetWeight))}
            />
          ) : (
            <Advantage selectedFlow={isMigrationToTT ? FlowTypes.TripleTherapy : selectedFlow} />
          )}
        </FadeWrapper>
      )}
    </AnimatePresence>
  );
};

export default HeightAndWeight;
